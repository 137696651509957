import React, { useState, useEffect } from 'react';
import { RadioGroup, FormControlLabel, Radio, Typography, Box } from '@mui/material';
import userService from '../services/UserService';
import authService from '../services/AuthService';
import { useTranslation } from 'react-i18next';

const Settings = () => {
    const { t } = useTranslation();

    const [mfaMethod, setMfaMethod] = useState('');

    const handleMfaChange = (event) => {
        let user = {
            username: authService.getCurrentUser(),
            mfa: event.target.value
        };
        userService.updateUser(user).then(() => {
            setMfaMethod(event.target.value);
        });
    };

    useEffect(() => {
        userService.getUser(authService.getCurrentUser()).then((user) => {
            setMfaMethod(user.mfa);
        });
    }, []);

    return (
        <Box sx={{ p: 4, maxWidth: 400, mx: 'auto', textAlign: 'center' }}>
            <Typography variant="h5" gutterBottom>
                {t('sideBar.settings')}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 2,
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    backgroundColor: 'background.paper',
                    ml: 0
                }}
            >
                <Typography variant="h6" gutterBottom>
                    {t('settings.settings')} (MFA)
                </Typography>
                <RadioGroup value={mfaMethod} onChange={handleMfaChange}>
                    {/* <FormControlLabel value="SOFTWARE_TOKEN_MFA" control={<Radio color="primary" />} label="Mobile App" /> */}
                    <FormControlLabel value="SMS_MFA" control={<Radio color="primary" />} label={t('settings.sms')} />
                    <FormControlLabel value="EMAIL_OTP" control={<Radio color="primary" />} label={t('settings.email')} />
                    <FormControlLabel value="NOMFA" control={<Radio color="primary" />} label={t('settings.none')} />
                </RadioGroup>
            </Box>
        </Box>
    );
};

export default Settings;