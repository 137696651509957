import React, { useEffect, useState } from 'react';
import AuthService from '../services/AuthService';
import UserService from '../services/UserService';
import UserForm from './UserForm';

import {
    Box,
    Typography,
    Container,
    TextField,
    Paper,
    Button,
    Avatar,
    Snackbar,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Switch,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Tooltip
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { MdDeleteForever } from "react-icons/md";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LoginIcon from '@mui/icons-material/Login';
import SchoolIcon from '@mui/icons-material/School';
import BaseList from './BaseList';

function Users({ handleLoginAs, profile }) {
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortBy, setSortBy] = useState('name'); // Sort option state
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const { t } = useTranslation();
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [dialogType, setDialogType] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);


    const handleAddDialog = () => {
        setDialogType('add');
        setOpenFormDialog(true);
    };

    const handleOpenDeleteDialog = (user) => {
        setDialogType('delete');
        setSelectedUser(user);
        setOpenFormDialog(true);
    };

    const handleOpenEditDialog = (user) => {
        setDialogType('edit');
        setSelectedUser(user);
        setOpenFormDialog(true);
    };

    const handleOpenBaseDialog = (user) => {
        setDialogType('base');
        setSelectedUser(user);
        setOpenFormDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenFormDialog(false);
        setDialogType('');
        setSelectedUser(null);
    };

    async function handleConfirmDelete() {
        try {
            await UserService.deleteUser(selectedUser.name);
            setUsers(users.filter(user => user.name !== selectedUser.name));
            handleCloseDialog();
        } catch (error) {
            console.error('Error deleting user:', error);
            setSnackbarMessage('Failed to delete user.');
            setOpenSnackbar(true);
        }
    }
    const UserCard = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }));

    const fetchUsers = async () => {
        try {
            const data = await UserService.getUsers(profile);
            setUsers(data);
        } catch (error) {
            console.error('Error fetching user list:', error);
        }
    };

    useEffect(() => {
        fetchUsers(profile);
    }, []);

    const handleToggleEnabled = async (user) => {
        try {
            await UserService.enableUser(user.name, !user.enabled);
            setUsers((prevUsers) =>
                prevUsers.map((c) =>
                    c.name === user.name ? { ...c, enabled: !c.enabled } : c
                )
            );
            setSnackbarMessage(`User ${user.name} ${!user.enabled ? 'enabled' : 'disabled'} successfully.`);
            setOpenSnackbar(true);
        } catch (error) {
            console.error('Error updating user status:', error);
            setSnackbarMessage('Failed to update user status.');
            setOpenSnackbar(true);
        }
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return new Intl.DateTimeFormat('ja-JP', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(date);
    };

    const handleSearchChange = (e) => setSearchQuery(e.target.value);

    const handleSortChange = (e) => setSortBy(e.target.value);

    const sortUsers = (users) => {
        return [...users].sort((a, b) => {
            if (sortBy === 'name') {
                return a.name.localeCompare(b.name);
            } else if (sortBy === 'date') {
                return new Date(b.createDate) - new Date(a.createDate);
            }
            return 0;
        });
    };

    const filteredUsers = sortUsers(
        users.filter((user) =>
            user.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    return (
        <Container maxWidth="false" sx={{ display: 'flex', flexDirection: 'column', mt: 4 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                <Typography variant="h5" fontWeight="bold">
                    {/* {profile === 'admin' ? t('users.systemAdminList') : t('users.userManagement')} */}
                    {profile === 'admin' && (t('sideBar.adminSetting'))}
                    {profile === 'customer' && (t('users.customerManagement'))}
                    {profile === 'member' && (t('users.clientMemberList'))}
                </Typography>
                <Box display="flex" alignItems="center">
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder={t('users.searchPlaceholder')}
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                        sx={{ mr: 2 }}
                    />
                    {/* Sort By Dropdown */}
                    <FormControl variant="outlined" size="small">

                        <Select
                            label={t('sort')}
                            value={sortBy}
                            onChange={handleSortChange}
                        >
                            <MenuItem value="name">{t('users.sortByName')}</MenuItem>
                            <MenuItem value="date">{t('users.sortByDate')}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                {/* Button to Open UserForm */}
                {profile === 'admin' && (
                    <Button variant="contained" color="primary" onClick={handleAddDialog}>
                        {t('users.addSystemAdmin')}
                    </Button>
                )}
                {profile === 'customer' && (
                    <Button variant="contained" color="primary" onClick={handleAddDialog}>
                        {t('users.addClient')}
                    </Button>
                )}
                {profile === 'member' && (
                    <Button variant="contained" color="primary" onClick={handleAddDialog}>
                        {t('users.addMember')}
                    </Button>
                )}
            </Box>
            {/* Dialog for UserForm */}
            <Dialog
                open={openFormDialog}
                onClose={handleCloseDialog}
                maxWidth="sm"
                fullWidth
                sx={{
                    '.MuiPaper-root': {
                        borderRadius: '12px', // Softer rounded corners
                        boxShadow: 'none', // Remove shadow for a cleaner look
                        border: '1px solid #ddd', // Subtle border for a modern look
                    }
                }}
            >
                <DialogTitle
                    id="confirmation-dialog"
                    sx={{
                        fontWeight: '600',
                        color: 'text.primary',
                        textAlign: 'center', // Center align title
                        fontFamily: 'Arial, sans-serif', // Clean font style
                        fontSize: '1.2rem', // Slightly larger font size for elegance
                        borderBottom: '1px solid #ddd', // Thin bottom border
                        pb: 1,
                    }}
                >
                    {dialogType === 'delete' && (t('users.deleteConfirmationTitle') + selectedUser.name)}
                    {dialogType === 'edit' && profile === 'customer' && (t('users.editClientTitle') + selectedUser.name)}
                    {dialogType === 'edit' && profile === 'admin' && (t('users.editSystemAdminTitle') + selectedUser.name)}
                    {dialogType === 'add' && profile === 'customer' && (t('users.addClientTitle'))}
                    {dialogType === 'add' && profile === 'admin' && (t('users.addSystemAdminTitle'))}
                    {dialogType === 'base' && (t('users.editBaseTitle') + selectedUser.name)}
                </DialogTitle>

                {dialogType === 'delete' && (
                    <DialogActions
                        sx={{
                            justifyContent: 'space-between',
                            borderTop: '1px solid #ddd', // Thin divider line
                            pt: 2,
                        }}
                    >
                        <Button
                            onClick={handleCloseDialog}
                            sx={{
                                color: '#555', // Dark gray color
                                borderRadius: '8px',
                                textTransform: 'none',
                                fontSize: '0.875rem',
                                padding: '6px 16px',
                                backgroundColor: 'transparent',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0', // Light hover effect
                                },
                            }}
                        >
                            {t('users.cancel')}
                        </Button>
                        <Button
                            onClick={handleConfirmDelete}
                            color="secondary"
                            variant="contained"
                            sx={{
                                borderRadius: '8px',
                                textTransform: 'none',
                                fontSize: '0.875rem',
                                padding: '6px 16px',
                                backgroundColor: '#333', // Dark color for contrast
                                color: '#fff', // White text
                                '&:hover': {
                                    backgroundColor: '#444', // Slightly lighter on hover
                                },
                            }}
                        >
                            {t('users.confirmDelete')}
                        </Button>
                    </DialogActions>
                )}

                {(dialogType === 'add' || dialogType === 'edit') && (
                    <DialogContent sx={{ p: 3 }}>
                        <UserForm
                            key={dialogType === 'edit' ? selectedUser.name : profile}
                            operType={dialogType}
                            profile={profile}
                            user={dialogType === 'edit' ? selectedUser : undefined}
                            onCancel={handleCloseDialog}
                            onUserAddOrUpdate={() => {
                                handleCloseDialog();
                                fetchUsers();
                            }}
                            sx={{
                                '& .MuiTextField-root': {
                                    mb: 2, // Space between form fields
                                    '& .MuiInputBase-root': {
                                        backgroundColor: '#f9f9f9', // Light gray background
                                        borderRadius: '8px', // Rounded input fields
                                    },
                                },
                            }}
                        />
                    </DialogContent>
                )}
                {dialogType === 'base' && (
                    <DialogContent sx={{ p: 3 }}>
                        <BaseList key={selectedUser} clientId={selectedUser.name} onCancel={handleCloseDialog} />
                    </DialogContent>
                )}
            </Dialog>


            {/* User List */}
            <Box>
                {filteredUsers.map((user) => (
                    <UserCard key={user.name}>
                        <Box display="flex" flexDirection="column">
                            <Box display="flex" alignItems="center" mb={1}>
                                <Avatar sx={{ mr: 2 }}>{user.name[0].toUpperCase()}</Avatar>
                                <Box>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        {user.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {user.email}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {t('users.phoneNumber')} {user.phoneNumber}
                                    </Typography>

                                    <Typography variant="caption" color="text.secondary">
                                        {t('users.createdDate')}: {formatDate(user.createDate)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box display="flex" alignItems="center">
                            {profile === 'customer' && user.bases && (
                                <div>
                                    <ul>
                                        {user.bases.map((item, index) => (
                                            <li key={index}>{item}</li> // Use `key` to give each item a unique identifier
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {profile === 'customer' && user.bases && (
                                <SchoolIcon onClick={() => handleOpenBaseDialog(user)} sx={{ ml: 2 }} style={{ cursor: 'pointer' }} />
                            )}
                            {profile === 'customer' && !user.bases && (
                                <SchoolIcon onClick={() => handleOpenBaseDialog(user)} sx={{ ml: 2 }} style={{ cursor: 'pointer', color: "red" }} />
                            )}
                            <EditIcon onClick={() => handleOpenEditDialog(user)} sx={{ ml: 2 }} style={{ cursor: 'pointer' }} />
                            <DeleteIcon sx={{ ml: 2 }}
                                onClick={() => handleOpenDeleteDialog(user)} // Pass the parameter here
                                style={{ cursor: 'pointer' }}
                            />
                            {/* Enable Switch with Label */}
                            <Tooltip placement="top" title={t('users.enabled')} arrow >

                                <Switch
                                    checked={user.enabled}
                                    color="black" onChange={() => handleToggleEnabled(user)}
                                />
                            </Tooltip>
                            {profile === 'customer' && (
                                <Tooltip placement="top" title={t('users.loginAs')} arrow>
                                    <IconButton onClick={() => handleLoginAs(user.name)}>
                                        <LoginIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    </UserCard>
                ))}
            </Box>

            {/* Snackbar Notification */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default Users;
